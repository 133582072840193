define("discourse/plugins/discourse-shared-edits/initializers/shared-edits-init", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/plugins/discourse-shared-edits/lib/shared-edits", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object/computed", "discourse/models/composer"], function (_exports, _pluginApi, _decorators, _sharedEdits, _ajax, _ajaxError, _computed, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const SHARED_EDIT_ACTION = "sharedEdit";
  const PLUGIN_ID = "discourse-shared-edits";
  function replaceButton(buttons, find, replace) {
    const idx = buttons.indexOf(find);
    if (idx !== -1) {
      buttons[idx] = replace;
    }
  }
  function initWithApi(api) {
    var _dec, _obj, _dec2, _obj2, _dec3, _dec4, _obj3;
    _composer.SAVE_LABELS[SHARED_EDIT_ACTION] = "composer.save_edit";
    _composer.SAVE_ICONS[SHARED_EDIT_ACTION] = "pencil-alt";
    api.includePostAttributes("shared_edits_enabled");
    api.addPostClassesCallback(attrs => {
      if (attrs.shared_edits_enabled && attrs.canEdit) {
        return ["shared-edits-post"];
      }
    });
    api.addPostMenuButton("sharedEdit", post => {
      if (!post.shared_edits_enabled || !post.canEdit) {
        return;
      }
      const result = {
        action: SHARED_EDIT_ACTION,
        icon: "far-edit",
        title: "shared_edits.button_title",
        className: "shared-edit create fade-out",
        position: "last"
      };
      if (!post.mobileView) {
        result.label = "shared_edits.edit";
      }
      return result;
    });
    api.removePostMenuButton("edit", attrs => {
      return attrs.shared_edits_enabled && attrs.canEdit;
    });
    api.removePostMenuButton("wiki-edit", attrs => {
      return attrs.shared_edits_enabled && attrs.canEdit;
    });
    api.reopenWidget("post-menu", {
      menuItems() {
        const result = this._super(...arguments);

        // wiki handles the reply button on its own. If not a wiki and is shared-edit
        // remove the label from the reply button.
        if (this.attrs.shared_edits_enabled && this.attrs.canEdit && !this.attrs.wiki) {
          replaceButton(result, "reply", "reply-small");
        }
        return result;
      },
      sharedEdit() {
        const post = this.findAncestorModel();
        this.appEvents.trigger("shared-edit-on-post", post);
      }
    });
    api.reopenWidget("post-admin-menu", {
      html(attrs) {
        const contents = this._super(...arguments);
        if (!(this.currentUser.staff || this.currentUser.trust_level > 3) || !contents.children) {
          return contents;
        }
        contents.children.push(this.attach("post-admin-menu-button", {
          action: "toggleSharedEdit",
          icon: "far-edit",
          className: "admin-collude",
          label: attrs.shared_edits_enabled ? "shared_edits.disable_shared_edits" : "shared_edits.enable_shared_edits"
        }));
        return contents;
      },
      toggleSharedEdit() {
        const post = this.findAncestorModel();
        let url = `/shared_edits/p/${post.id}/${post.shared_edits_enabled ? "disable" : "enable"}.json`;
        (0, _ajax.ajax)(url, {
          type: "PUT"
        }).then(() => {
          post.set("shared_edits_enabled", post.shared_edits_enabled ? false : true);
          this.scheduleRerender();
        }).catch(_ajaxError.popupAjaxError);
      }
    });
    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,
      sharedEdit() {
        this.appEvents.trigger("shared-edit-on-post");
      }
    });
    api.modifyClass("model:composer", (_dec = (0, _decorators.default)("action"), (_obj = {
      pluginId: PLUGIN_ID,
      creatingSharedEdit: (0, _computed.equal)("action", SHARED_EDIT_ACTION),
      editingPost() {
        return this._super(...arguments) || this.creatingSharedEdit;
      }
    }, (_applyDecoratedDescriptor(_obj, "editingPost", [_dec], Object.getOwnPropertyDescriptor(_obj, "editingPost"), _obj)), _obj)));
    api.modifyClass("component:composer-presence-display", {
      pluginId: PLUGIN_ID,
      _typing() {
        if (this.model.action === SHARED_EDIT_ACTION) {
          const lastKey = this.model.lastKeyPress;
          if (!lastKey || lastKey < Date.now() - 2000) {
            return;
          }
        }
        this._super(...arguments);
      }
    });
    api.modifyClass("component:composer-editor", (_dec2 = (0, _decorators.on)("keyDown"), (_obj2 = {
      pluginId: PLUGIN_ID,
      _trackTyping() {
        if (this.composer.action === SHARED_EDIT_ACTION) {
          this.composer.set("lastKeyPress", Date.now());
        }
      }
    }, (_applyDecoratedDescriptor(_obj2, "_trackTyping", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "_trackTyping"), _obj2)), _obj2)));
    api.modifyClass("controller:topic", {
      pluginId: PLUGIN_ID,
      init() {
        this._super(...arguments);
        this.appEvents.on("shared-edit-on-post", this, "_handleSharedEditOnPost");
      },
      _handleSharedEditOnPost(post) {
        const draftKey = post.get("topic.draft_key");
        const draftSequence = post.get("topic.draft_sequence");
        this.get("composer").open({
          post,
          action: SHARED_EDIT_ACTION,
          draftKey,
          draftSequence
        });
      },
      willDestroy() {
        this.appEvents.off("shared-edit-on-post", this, "_handleSharedEditOnPost");
        this._super(...arguments);
      }
    });
    api.modifyClass("controller:composer", (_dec3 = (0, _decorators.on)("init"), _dec4 = (0, _decorators.observes)("model.reply"), (_obj3 = {
      pluginId: PLUGIN_ID,
      open(opts) {
        const openResponse = this._super(opts);
        if (openResponse && openResponse.then) {
          return openResponse.then(() => {
            if (opts.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.setupSharedEdit)(this.model);
            }
          });
        }
      },
      collapse() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          return this.close();
        }
        return this._super();
      },
      close() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          (0, _sharedEdits.teardownSharedEdit)(this.model);
        }
        return this._super();
      },
      save() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          return this.close();
        }
        return this._super.apply(this, arguments);
      },
      _listenForClose() {
        this.appEvents.on("composer:close", () => {
          this.close();
        });
      },
      _handleSharedEdit() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          (0, _sharedEdits.performSharedEdit)(this.model);
        }
      },
      _saveDraft() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          return;
        }
        return this._super();
      }
    }, (_applyDecoratedDescriptor(_obj3, "_listenForClose", [_dec3], Object.getOwnPropertyDescriptor(_obj3, "_listenForClose"), _obj3), _applyDecoratedDescriptor(_obj3, "_handleSharedEdit", [_dec4], Object.getOwnPropertyDescriptor(_obj3, "_handleSharedEdit"), _obj3)), _obj3)));
  }
  var _default = _exports.default = {
    name: "discourse-shared-edits",
    initialize: container => {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.6", initWithApi);
    }
  };
});